import { createRouter, createWebHashHistory } from "vue-router";

import Home from "./views/home-page";
import defaultLayout from "./layouts/side-nav-outer-toolbar";
import simpleLayout from "./layouts/single-card";
import Consulta_padrao from "./views/consulta-padrao.vue"
import Cadastro_tela from "./views/criar_tela.vue"
import Cadastro_campos from "./views/criar_campos.vue"
import Login from "./views/login-form.vue"
import Kanban_padrao from "./views/kanban_padrao.vue"
import Cadastro_ativo from "./views/cadastro_ativo"
import Profile from "./views/profile-page"
import Mapa_localizacao_sonda from "./empresa/sonda/mapa_localizacao_sonda_otimizado.vue"
import UploadFile from "./views/upload-arquivos"
import Calendar_padrao from "./components/calendar_padrao"
import Blog from "./blog/blog_home"
import Gerenciador_arquivos from "./views/gerenciador_arquivos"
import Kanban_cws from "./views/kanban_cws.vue"
import Suporte_cws from "./suporte/suporte_login.vue"

const router = new createRouter({
  routes: [
    {
      path: "/home:id",
      name: "home",
      meta: {
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/consulta_padrao:id",
      name: "consulta-padrao",
      meta: {
        layout: defaultLayout
      },
      component: Consulta_padrao
    },
    {
      path: "/gerenciador_arquivos:id",
      name: "gerenciador_arquivos",
      meta: {
        layout: defaultLayout
      },
      component: Gerenciador_arquivos
    },
    {
      path: "/cadastro_tela:id",
      name: "cadastro_tela",
      meta: {
        layout: defaultLayout
      },
      component: Cadastro_tela
    },
    {
      path: "/cadastro_ativo:id",
      name: "cadastro_ativo",
      meta: {
        layout: defaultLayout
      },
      component: Cadastro_ativo
    },
    {
      path: "/calendar_padrao:id",
      name: "calendar_padrao",
      meta: {
        layout: defaultLayout
      },
      component: Calendar_padrao
    },
    {
      path: "/kanban_padrao:id",
      name: "kanban_padrao",
      meta: {
        layout: defaultLayout
      },
      component: Kanban_padrao
    },
    {
      path: "/kanban_cws:id",
      name: "kanban_cws",
      meta: {
        layout: defaultLayout
      },
      component: Kanban_cws
    },
    {
      path: "/mapa_localizacao_sonda:id",
      name: "mapa_localizacao_sonda",
      meta: {
        layout: defaultLayout
      },
      component: Mapa_localizacao_sonda
    },
    {
      path: "/cadastro_campos:id",
      name: "cadastro_campos",
      meta: {
        layout: defaultLayout
      },
      component: Cadastro_campos
    },
    {
      path: "/upload-file:id",
      name: "upload-file",
      meta: {
        layout: defaultLayout
      },
      component: UploadFile
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        layout: simpleLayout,
        title: "Sign In"
      },
      component: Login
    },
    {
      path: "/blog",
      name: "blog",
      meta: {
        layout: Blog
      },
      component: Blog
    },
    {
      path: "/suporte",
      name: "suporte",
      meta: {
        layout: simpleLayout
      },
      component: Suporte_cws
    },
    {
      path: "/",
      redirect: "/login-form"
    },
    {
      path: "/recovery",
      redirect: "/login-form"
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/login-form'
    }
  ],
  history: createWebHashHistory()
});

export default router;