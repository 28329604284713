<template>
  <div v-if="ativo!=undefined">
    <div class="">
      <div>
        <span class="span_p_sonda">Descrição:          </span>{{ ativo.nm_descricao }}<br>
        <span class="span_p_sonda">Número de série:    </span>{{ ativo.nm_serial_number }}<br>
        <span class="span_p_sonda">Fábricante:         </span>{{ ativo.nm_fabricante }}<br>
        <span class="span_p_sonda">Quantidade de Us:   </span>{{ ativo.qt_us }}<br>
        <span class="span_p_sonda">Cliente:            </span>{{ ativo.nm_cliente }} <br>
        <span class="span_p_sonda">Proprietário:       </span>{{ ativo.nm_proprietario }} <br>
        <span class="span_p_sonda">Data de instalação: </span>{{ ativo.dt_instalacao }}
      </div>
      <div class="div_imagem" v-if="ativo.cd_tipo_item == 1">
        <img :src="ativo.nm_caminho_imagem" alt="" :style="{ height: Math.min(ativo.qt_us * 30, 500) + 'px', width: 'auto' }" @click="openImagePopup(ativo.nm_caminho_imagem)" />
      </div>
      <div class="div_imagem_vazio" v-else>
        <img :src="ativo.nm_caminho_imagem" alt="" :height="(ativo.qt_us * 30)+'px'" width="40%" @click="openImagePopup(ativo.nm_caminho_imagem)"/>
      </div>
      <br>
    </div>
    <div v-if="isModalOpen" class="modal-overlay" @click="closeImagePopup">
      <div class="modal-content">
        <img :src="selectedImage" alt="Imagem maior" />
      </div>
    </div>
  </div>
</template>
<script>
export default{
    props:{
        ativo: Object,
        ativos: Array
    },
    data() {
        return {
          isModalOpen: false,
          selectedImage: null,
        };
    },
    methods:{
        openImagePopup(imageUrl) {
      this.selectedImage = imageUrl;
      this.isModalOpen = true; 
    },
    closeImagePopup() {
      this.isModalOpen = false;
    },
    }
}
</script>
<style>
.infos_ativo_sonda{
  display: flex;
  align-items: center;
}
.span_p_sonda{
  margin-right: 10px;
  color: rgb(0, 0, 0);
  font-weight: bold;
}
.div_imagem{
    width: auto;
    object-fit: contain;
    max-height: 450px;
}
.div_imagem_vazio{
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 200px; 
    overflow: hidden;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 130vh;
}
.modal-content {
    background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 900px;
  height: auto;
  max-height: 80vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content img {
  max-width: 100%;
  max-height: 70vh;
}
</style>