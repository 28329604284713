<template>
    <div id="padrao">
        <h2 v-if="tipo_tela != 3" class="content-block">{{ tela.text }}</h2>
        <DxForm :form-data="formData" class="box" v-if="tipo_tela == 1 || tipo_tela == 3">
            <DxGroupItem :col-count="tela.quant" css-class="form-group">
                <DxSimpleItem v-for="campo in campos" :editor-options="campo.options[0]" v-bind:key="campo.nm_campo"
                    :data-field="campo.nm_campo" :editor-type="campo.tipo_campo" :template="campo.template">
                    <DxLabel :text="campo.options[0].label" />
                </DxSimpleItem>
            </DxGroupItem>
            <template #DxColorBox="{ data }">
                <DxColorBox v-model="formData[data.editorOptions.name]" />
            </template>
            <template #img="{ data }">
                <button class="imgbutton" @click="OpenImage(formData[data.editorOptions.name])"><img max-width="180"
                        height="80" :src="formData[data.editorOptions.name]" /></button>
            </template>
            <template #DxTagBox="{ data }">
                <dxTagBox v-model="formData[data.editorOptions.name]" :items="data.editorOptions.items"
                    :display-expr="data.editorOptions.displayExpr" :value-expr="valueExpr" />
            </template>
            <template #File-uploader="{ data }">
                <input class="imgbutton" type="file" id="nm_arquivos" @change="add_arquivos(data.editorOptions.name)" multiple>
            </template>
            <template #DxHtmlEditor="{ data }">
                <DxHtmlEditor ref="htmlEditor" v-model="formData[data.editorOptions.name]" height="725px"
                    :element-attr="{ style: 'border-color: black;' }">
                    <DxMediaResizing :enabled="true" />
                    <DxImageUpload :tabs="currentTabs" />
                    <DxToolbar :multiline="true">
                        <DxItem name="undo" />
                        <DxItem name="redo" />
                        <DxItem name="separator" />
                        <DxItem name="size" :accepted-values="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"
                            :options="{ inputAttr: { 'aria-label': 'Font size' } }" />
                        <DxItem name="font"
                            :accepted-values="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']"
                            :options="{ inputAttr: { 'aria-label': 'Font family' } }" />
                        <DxItem name="separator" />
                        <DxItem name="bold" />
                        <DxItem name="italic" />
                        <DxItem name="strike" />
                        <DxItem name="underline" />
                        <DxItem name="separator" />
                        <DxItem name="alignLeft" />
                        <DxItem name="alignCenter" />
                        <DxItem name="alignRight" />
                        <DxItem name="alignJustify" />
                        <DxItem name="separator" />
                        <DxItem name="orderedList" />
                        <DxItem name="bulletList" />
                        <DxItem name="separator" />
                        <DxItem name="header" :accepted-values="headerValues" :options="headerOptions" />
                        <DxItem name="separator" />
                        <DxItem name="color" />
                        <DxItem name="background" />
                        <DxItem name="separator" />
                        <DxItem name="link" />
                        <DxItem name="image" />
                        <DxItem name="video" location="after" :options="{ onClick: openYouTubeDialog }" />
                        <DxItem name="separator" />
                        <DxItem name="clear" />
                        <DxItem name="codeBlock" />
                        <DxItem name="blockquote" />
                        <DxItem name="separator" />
                        <DxItem name="insertTable" />
                        <DxItem name="deleteTable" />
                        <DxItem name="insertRowAbove" />
                        <DxItem name="insertRowBelow" />
                        <DxItem name="deleteRow" />
                        <DxItem name="insertColumnLeft" />
                        <DxItem name="insertColumnRight" />
                        <DxItem name="deleteColumn" />

                    </DxToolbar>
                </DxHtmlEditor>
                <DxPopup v-model:visible="isDialogVisible" :showCloseButton="true" :width="400" :height="300"
                    title="Video Youtube">
                    <template #content>
                        <div class="popup-content">
                            <div class="form-group">
                                <label for="youtubeUrl">*YouTube URL:</label>
                                <DxTextBox id="youtubeUrl" v-model="youtubeUrl" placeholder="Enter YouTube video URL"
                                    class="text-box" />
                            </div>
                            <DxButton class="youtube" icon="check" type="success" text="Add Video"
                                @click="insertYouTubeVideo" />
                        </div>
                    </template>
                </DxPopup>
            </template>
        </DxForm>
        <div v-else>
            <div v-if="formData!={}" class="box">
                <div v-for="campo in campos_grid" v-bind:key="campo">
                    <div v-if="campo.cellTemplate == undefined" class="item_string">
                        <b style="margin-right: 5px;">{{campo.caption}}:</b>{{formData[campo.dataField]}}
                    </div>
                    <div v-if="campo.cellTemplate == 'img'">
                        <button class="imgbutton" @click="OpenImage(formData[campo.dataField])"><img max-width="180"
                            height="80" :src="formData[campo.dataField]" /></button>
                    </div>
                </div>
            </div>
        </div>
        <DxList v-if="formData.nm_arquivos != undefined" class="box" :allow-item-deleting="true" item-delete-mode="toggle"
            @item-deleted="deleted_item">
            <DxItem v-for="arq in url_files" v-bind:key="arq">
                <div class="button_item_file">{{ arq.url }} <a :href="arq.url" target="_blank"
                        rel="noopener noreferrer">visualizar anexo</a></div>
            </DxItem>
        </DxList>
        <div class="box-buttons" v-if="tipo_tela == 1 || tipo_tela == 3">
            <DxButton v-if="cadastro" class="DxButton" icon="check" type="success" text="Confirmar Cadastro"
                @click="cadastrar()" />
            <DxButton v-else class="DxButton" icon="check" type="success" text="Confirmar Alteração"
                @click="alterar()" />
            <DxButton class="DxButton" icon="clear" type="danger" text="Limpar" @click="limpar()" />
            <DxButton class="DxButton" icon="pulldown" type="default" text="Atualizar Lista" @click="carrega_dados()" />
        </div>
        <DxDataGrid class="DxDataGrids" v-if="tipo_tela == 1 || tipo_tela == 2" @exporting="onExporting"
            :data-source="dados_grid" :key-expr="tela.nm_chave_grid" :selection="{ mode: 'single' }"
            :columns="campos_grid" :show-borders="true" :focused-row-enabled="true" :row-alternation-enabled="true"
            :column-auto-width="true" @selection-changed="onChange_linha">
            <DxRemoteOperations :group-paging="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="true" />
            <DxScrolling column-rendering-mode="virtual" />
            <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
            <DxPaging :page-size="10" />
            <DxFilterRow :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxExport :enabled="true" :allow-export-selected-data="true">
            </DxExport>
            <template #img="{ data }">
                <button class="imgbutton" @click="OpenImage(data.value)"><img :src="data.value" max-width="130"
                        height="45" alt=""></button>
            </template>
            <template #link="{ data }">
                <div v-if="data.value != undefind">
                    <a :href="data.value" target="_blank">Acessar</a>
                </div>
                <div v-else>
                    Nenhum arquivo anexado
                </div>
            </template>
            <template #ButtomGrid="{ data }">
                <div v-if="campos_grid[data.columnIndex]">
                    <DxButton :type="campos_grid[data.columnIndex].type" class="buttomgrid" :width="120"
                        @click="exec_button(campos_grid[data.columnIndex].nm_api)">{{
                            campos_grid[data.columnIndex].caption }}</DxButton>
                </div>
            </template>
        </DxDataGrid>
    </div>
    <DxPopup :visible="show_popup" :shadding="true" height="50%" width="50%" :resize-enabled="true">
        <DxToolbarItem widget="dxButton" toolbar="top" location="after" :options="button_cancelar" />
        <img style='height: 90%; width: 100%; object-fit: contain' :src="item_click" alt="">
    </DxPopup>
    <DxLoadPanel
      position="#padrao"
      :visible="load"
      show-indicator="true"
      show-pane="true"
      shading="true"
      :hide-on-outside-click="false"
      shading-color="rgba(0,0,0,0.4)"
    />
</template>
<script>
import {
    DxHtmlEditor,
    DxToolbar,
    DxMediaResizing,
    DxImageUpload,
} from 'devextreme-vue/html-editor';
import {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxLabel,
} from 'devextreme-vue/form';
import DxList, { DxItem } from 'devextreme-vue/list';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import DxTagBox from 'devextreme-vue/tag-box';
import DxTextBox from 'devextreme-vue/text-box';
import {
    DxDataGrid,
    DxRemoteOperations,
    DxGroupPanel,
    DxGrouping,
    DxSearchPanel,
    DxPaging,
    DxScrolling,
    DxExport,
    DxFilterRow,
    DxHeaderFilter
} from 'devextreme-vue/data-grid'
import saveAs from 'file-saver';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import DxColorBox from 'devextreme-vue/color-box';
import DxButton from 'devextreme-vue/button'
import api from '../api/api'
import notify from 'devextreme/ui/notify'
import { DxLoadPanel } from 'devextreme-vue/load-panel';
export default {
    props: {
        kanban: Boolean,
        projeto: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            isDialogVisible: false,
            youtubeUrl: '',
            editorValue: '',
            seleciona_grid: null,
            formData: {},
            campos: [],
            tela: {},
            cadastro: true,
            campos_grid: [],
            dados_grid: [],
            tipo_tela: 0,
            show_popup: false,
            button_cancelar: {
                width: 15,
                icon: 'close',
                type: 'danger',
                onClick: () => {
                    this.show_popup = false
                }
            },
            item_click: '',
            qt_itens_add: 0,
            url_files: [],
            atualiza: '',
            interval_atualizacao: '',
            load: true
        }
    },
    watch: {
        '$route.path': function () {
            //this.limpar_tudo()
            //this.carrega_tela()
            //this.carrega_dados()
            clearInterval(this.interval_atualizacao)
            //window.location.reload()
        }
    },
    created() {
        this.load = true
        this.limpar_tudo()
        this.carrega_tela()
        this.carrega_dados()
        this.load = false
    },
    mounted(){
        if(this.projeto != undefined && this.kanban){
            this.formData = this.projeto
            console.log('PROJETO',this.projeto)
            if(this.projeto=={}){
                this.cadastro = true    
            }
            else{
                this.cadastro = false
            }
        }
    },
    
    methods: {

        openYouTubeDialog() {
            this.isDialogVisible = true;
        },

        extractVideoId(url) {
            let videoId = null;

            if (url.includes('youtube.com/watch?v=')) {
                videoId = url.split('youtube.com/watch?v=')[1].split('&')[0];
            } else if (url.includes('youtube.com/embed/')) {
                videoId = url.split('youtube.com/embed/')[1].split('?')[0];
            } else if (url.includes('youtu.be/')) {
                videoId = url.split('youtu.be/')[1].split('?')[0];
            }

            return videoId;
        },

        insertYouTubeVideo() {
            const videoId = this.extractVideoId(this.youtubeUrl);
            if (videoId) {
                const thumbnailUrl = `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`
                const videoUrl = `https://www.youtube.com/watch?v=${videoId}`;

                // Criar o HTML do thumbnail do vídeo
                const videoHtml = `
          <a href="${videoUrl}" target="_blank" rel="noopener noreferrer">
            <img src="${thumbnailUrl}" alt="YouTube Video"  style="cursor: pointer; width:450px" />
          </a>
        `;


                const editor = this.$refs.htmlEditor.instance;
                const currentContent = editor.option('value');
                editor.option('value', currentContent + videoHtml);
                this.isDialogVisible = false;
                this.youtubeUrl = '';
            } else {
                alert('Por favor, insira uma URL válida do YouTube.');
            }
        },

        onFileChange(e) {
            const files = e.component.option("value");
            if (files.length > 0) {
                const file = files[0];
                this.videoFileUrl = URL.createObjectURL(file);
                this.videoTextUrl = this.videoFileUrl;
                this.$nextTick(() => {
                    document.querySelector('.caminho').textContent = this.videoFileUrl;
                });
            }
        },
        deleted_item(e) {
            console.log(e)
            this.url_files.splice(e.itemIndex, 1)
            this.formData['nm_arquivos']=this.url_files
        },
        async add_arquivos(id) {
            var arq = document.getElementById(id).files
            for (var a = 0; a < arq.length; a++) {
                console.log(a)
                var url = await api.upload_file(arq[a])
                this.url_files.push(url)
            }
            console.log(this.url_files)
            this.formData[id]=this.url_files
        },
        async exec_button(api_button) {
            var result = await api.post(api_button, this.seleciona_grid)
            notify(
                { message: result['Message'], type: 'success', displayTime: 3500, animation: { show: { type: 'fade', duration: 400, from: 0, to: 1, }, hide: { type: 'fade', duration: 40, to: 0 }, }, }, { position: 'bottom center' }
            );
            this.carrega_dados();
        },
        getvmodel(data) {
            return `formData.${data.name}`
        },
        campos_tela() {
            return this.campos.filter(i => i.show_tela == 'S')
        },
        async carrega_tela() {
            this.tela = JSON.parse(localStorage.getItem("tela"))
            this.tipo_tela = this.tela.cd_tipo_tela
            this.atualiza = this.tela.nm_atualiza
            if (this.atualiza == 'S') {
                this.interval_atualizacao = setInterval(this.carrega_dados, 10000)
            }
            if (localStorage.getItem("campos") == undefined) {
                this.campos = []
            }
            else {
                var campos_c = JSON.parse(localStorage.getItem("campos"))
                campos_c.sort(function (a, b) {
                    console.log(a)
                    if (a.ordem > b.ordem) {
                        return 1
                    }
                    if (a.ordem < b.ordem) {
                        return -1
                    }
                    return 0
                })
                const promise = campos_c.map(async (element_campo) => {
                    if (element_campo.tipo_campo == 'dxSelectBox' || element_campo.tipo_campo == 'DxTagBox') {
                        var parametro = element_campo.options[0].nm_parametro_api
                        if (parametro == undefined) {
                            element_campo.options[0].items = await api.get(element_campo.options[0].nm_api, [])
                        }
                        else {
                            element_campo.options[0].items = await api.get(element_campo.options[0].nm_api, [parametro])
                        }
                    }
                    console.log(element_campo)
                })
                await Promise.all(promise)
                this.campos = campos_c.filter(c => c.show_tela == 'S')
            }
            if (localStorage.getItem("campos_grid") == 'undefined' || localStorage.getItem("campos_grid") == undefined) {
                this.campos_grid = []
            }
            else {
                console.log('CARREGOU')
                this.campos_grid = JSON.parse(localStorage.getItem("campos_grid"))
                console.log(this.campos_grid)
            }
        },
        async cadastrar() {
            console.log(this.formData)
            var result = await api.post(this.tela.nm_caminho_cadastro, this.formData)
            console.log('RESULT', result)
            if (result.Code == 0 || result.code == 0) {
                notify({ message: result.Message, type: 'success', displayTime: 3500, animation: { show: { type: 'fade', duration: 400, from: 0, to: 1, }, hide: { type: 'fade', duration: 40, to: 0 }, }, }, { position: 'bottom center' })
            }
            if (result.Code == 1 || result.code == 1) {
                notify({ message: result.Message, type: 'error', displayTime: 3500, animation: { show: { type: 'fade', duration: 400, from: 0, to: 1, }, hide: { type: 'fade', duration: 40, to: 0 }, }, }, { position: 'bottom center' })
            }
            this.carrega_dados()
        },
        async alterar() {
            var result = await api.post(this.tela.nm_caminho_edita, this.formData)
            if (result.Code == 0 || result.code == 0) {
                notify({ message: result.Message, type: 'success', displayTime: 3500, animation: { show: { type: 'fade', duration: 400, from: 0, to: 1, }, hide: { type: 'fade', duration: 40, to: 0 }, }, }, { position: 'bottom center' })
            }
            if (result.Code == 1 || result.code == 1) {
                notify({ message: result.Message, type: 'error', displayTime: 3500, animation: { show: { type: 'fade', duration: 400, from: 0, to: 1, }, hide: { type: 'fade', duration: 40, to: 0 }, }, }, { position: 'bottom center' })
            }
            this.carrega_dados()
        },
        async carrega_dados() {
            if(this.kanban != true){
                console.log('CAMINHO CONSULTA', this.tela.nm_caminho_consulta)
                this.dados_grid = await api.get(this.tela.nm_caminho_consulta, [])
            }
        },
        limpar() {
            this.formData = {}
            if (this.formData.nm_arquivos != undefined) {
                document.getElementById('nm_arquivos').value = []
            }
            this.cadastro = true
            this.formData = {}
            this.url_files = []
        },
        limpar_tudo() {
            if (this.formData.nm_arquivos != undefined) {
                document.getElementById('nm_arquivos').value = []
            }
            this.cadastro = true
            this.formData = {}
            this.dados_grid = []
            this.campos = []
            this.campos_grid = []
        },
        onChange_linha({ selectedRowsData }) {
            this.url_files = []
            const data = selectedRowsData[0]
            this.formData = data
            this.seleciona_grid = data
            console.log(this.formData)
            if (this.formData.nm_arquivos != undefined || this.formData.nm_arquivos != null) {
                this.formData.nm_arquivos.split(',').forEach(element => this.url_files.push({ url: element }))
            }
            console.log('URL FILES',this.url_files)
            this.cadastro = false
        },
        onExporting(e) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('Main sheet');
            exportDataGrid({
                component: e.component,
                worksheet: worksheet,
                customizeCell: function (options) {
                    options.excelCell.font = { name: 'Arial', size: 12 };
                    options.excelCell.alignment = { horizontal: 'left' };
                }
            }).then(function () {
                workbook.xlsx.writeBuffer()
                    .then(function (buffer) {
                        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `datagrid.xlsx`);
                    })
            });
        },
        OpenImage(e) {
            this.item_click = e
            this.show_popup = true
        }
    },
    components: {
        DxTextBox,
        DxForm,
        DxSimpleItem,
        DxGroupItem,
        DxButton,
        DxDataGrid,
        DxRemoteOperations,
        DxGroupPanel,
        DxGrouping,
        DxSearchPanel,
        DxPaging,
        DxScrolling,
        DxLabel,
        DxColorBox,
        DxExport,
        DxPopup,
        DxToolbarItem,
        DxFilterRow,
        DxHeaderFilter,
        DxTagBox,
        DxList,
        DxItem,
        DxHtmlEditor,
        DxToolbar,
        DxMediaResizing,
        DxImageUpload,
        DxLoadPanel
    }
}
</script>
<style>
.box {
    margin: auto 5vh;
}

.box-buttons {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    margin: auto 5vh;
}

.DxButton {
    margin: 1vh 1vh;
}

.DxDataGrids {
    margin: 5vh;
}

.imgbutton {
    background-color: transparent;
    border-color: transparent;
}

.button_item_file {
    padding: 10px 20px;
}

.youtube {
    margin-top: 10px;
}

.item_string{
    margin: auto
}
</style>