<template>
  <div>
    <h2 class="content-block">Cadastro e Alteração de Campos</h2>
    <span class="content-block" style="font-size: medium;">Código da tela: {{ cd_tela_empresa }}</span>
    <div class="box-buttons">
      <DxSelectBox class="DxTextBox" :data-source="telas_empresa" display-expr="nm_tela_empresa"
        value-expr="cd_tela_empresa" search-expr="cd_tela_empresa" label="TELA EMPRESA" v-model="cd_tela_empresa"
        show-data-before-search="false" @value-changed="onChangeCampo" />
    </div>
    <div v-if="show" class="box-buttons">
      <DxTextBox v-model="nm_campo" label="NOME DO CAMPO (NO BANCO DE DADOS)" class="DxTextBox" />
      <DxSelectBox class="DxTextBox" :data-source="tipos_campos" display-expr="nm_tipo_campo" value-expr="cd_tipo_campo" search-expr="nm_tipo_campo" label="TIPO DO CAMPO" v-model="cd_tipo_campo" :show-clear-button="true" :search-enabled="true"/>
      <DxTextBox v-model="show_grid" label="MOSTRA NA GRID" class="DxTextBox" />
      <DxTextBox v-model="show_tela" label="MOSTRA NA TELA" class="DxTextBox" />
      <DxSelectBox class="DxTextBox" :data-source="tipos_campos" display-expr="nm_tipo_campo" value-expr="cd_tipo_campo" search-expr="nm_tipo_campo" label="TEMPLATE" v-model="cd_template"  :show-clear-button="true" :search-enabled="true"/>
      <DxNumberBox class="DxTextBox" v-model="cd_ordem" label="ORDEM DO CAMPO NA TELA"/>
    </div>
    <div v-if="show" class="box">
      <DxButton v-if="status_cadastrocima" class="DxTextBox" icon="save" type="success"
        text="Confirmar cadastro de Campo" @click="cadastra_campo()" />
      <DxButton v-if="!status_cadastrocima" class="DxTextBox" icon="save" type="outline" text="Confirmar Alteração"
        @click="altera_telacima()" />
      <DxButton class="DxTextBox" icon="clear" type="danger" text="Limpar" @click="limparcima()" />
    </div>
    <br>
    <div v-if="show">
      <span class="content-block" style="font-size: medium;">Opções dos campos (options), código do campo: {{
        cd_campo_tela }}</span>
      <div v-if="show" class="box-buttons">
        <DxTextBox v-model="nm_placeholder_campo_tela" label="PLACEHOLDER (LABEL À MOSTRA)" class="DxTextBox" />
        <DxTextBox v-model="nm_api" label="API PARA CARREGAR O CAMPO" class="DxTextBox" />
        <DxTextBox v-model="nm_parametro_api" label="PARÂMETRO DA API" class="DxTextBox" />
        <DxTextBox v-model="displayExpr" label="DISPLAYEXPR" class="DxTextBox" />
        <DxTextBox v-model="valueExpr" label="VALUEEXPR" class="DxTextBox" />
        <DxTextBox v-model="searchExpr" label="SEARCHEXPR" class="DxTextBox" />
        <DxTextBox v-model="searchEnabled" label="SEARCHENABLED" class="DxTextBox" />
      </div>
      <div v-if="show" class="box">
        <DxButton v-if="status_cadastrobaixo" class="DxTextBox" icon="save" type="success"
          text="Confirmar cadastro de Options" @click="cadastra_options_campo()" />
        <DxButton v-if="!status_cadastrobaixo" class="DxTextBox" icon="save" type="outline" text="Confirmar Alteração"
          @click="altera_telabaixo()" />
        <DxButton class="DxTextBox" icon="clear" type="danger" text="Limpar" @click="limparbaixo()" />
      </div>
    </div>
    <div v-if="show">
      <DxDataGrid class="DxDataGrids" :data-source="telas" key-expr="cd_campo_tela" :selection="{ mode: 'single' }"
        :columns="columns" :show-borders="true" :focused-row-enabled="true" :row-alternation-enabled="true"
        :column-auto-width="true" @selection-changed="onChangeCampo2">
        <DxPager :allowed-page-sizes="pageSizes" :show-page-size-selector="true"/>
        <DxFilterRow :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxRemoteOperations :group-paging="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxSearchPanel :visible="true" :highlight-case-sensitive="true" />
        <DxExport :enabled="true" :allow-export-selected-data="true"> </DxExport>
        <DxPaging :page-size="10" />
      </DxDataGrid>
    </div>
  </div>
</template>

<script>
import api from '../api/api'
import {
  DxDataGrid,
  DxRemoteOperations,
  DxGroupPanel,
  DxGrouping,
  DxSearchPanel,
  DxPaging,
  DxScrolling,
  DxPager,
  DxFilterRow,
  DxExport,
  DxHeaderFilter,
} from 'devextreme-vue/data-grid'
import DxButton from 'devextreme-vue/button'
import DxTextBox from 'devextreme-vue/text-box'
import DxSelectBox from 'devextreme-vue/select-box'
import DxNumberBox from 'devextreme-vue/number-box'
export default {
  props: {
    picture: String
  },
  data() {
    return {
      show: false,
      status_cadastrobaixo: true,
      status_cadastrocima: true,
      telas_empresa: [],
      telas: [],
      cd_tela_empresa: 0,
      nm_campo: '',
      nm_placeholder_campo_tela: '',
      cd_campo_tela: 0,
      cd_tipo_campo: 0,
      nm_api: '',
      nm_parametro_api: '',
      tipos_campos: [],
      displayExpr: '',
      valueExpr: '',
      searchExpr: '',
      searchEnabled: '',
      show_grid: '',
      show_tela:'',
      cd_template: null,
      cd_ordem: null,
      columns: [
        'cd_campo_tela',
        'nm_campo',
        'cd_tipo_campo',
        'nm_api',
        'nm_parametro_api',
        'displayExpr',
        'valueExpr',
        'sarchExpr',
        'searchEnabled',
        'ordem'
      ]
    }
  },
  created() {
    this.carrega_dados()
  },
  methods: {
    async carrega_dados() {
      this.telas_empresa = await api.get('consulta_telas', [])
      this.tipos_campos = await api.get('consulta_tipo_campo', [])
    },
    async cadastra_campo() {
      var json = {
        'cd_tela_empresa': this.cd_tela_empresa,
        'nm_campo': this.nm_campo,
        'cd_tipo_campo': this.cd_tipo_campo,
        'show_grid': this.show_grid,
        'show_tela': this.show_tela,
        'cd_template': this.cd_template,
        'cd_ordem': this.cd_ordem
      }
      var result = await api.post('cadastra_campo_tela', json);
      this.cd_campo_tela = result.cd_campo_tela
      console.log(result)
      this.onChangeCampo()
    },
    async cadastra_options_campo() {
      var json = {
        'nm_placeholder': this.nm_placeholder_campo_tela,
        'nm_api': this.nm_api,
        'nm_parametro_api': this.nm_parametro_api,
        'displayExpr': this.displayExpr,
        'valueExpr': this.valueExpr,
        'searchExpr': this.searchExpr,
        'searchEnabled': this.searchEnabled,
        'cd_campo_tela': this.cd_campo_tela,
        'cd_ordem': this.cd_ordem
      }
      var result = await api.post('cadastro_options_campo', json);
      this.cd_campo_tela = result.cd_campo_tela
      console.log(result)
      this.onChangeCampo()
    },
    async altera_telacima() {
      var result = await api.post(`altera_campocima/${this.cd_tela_empresa}`, this.gera_json())
      console.log(result)
      this.limparcima()
      this.onChangeCampo()
      this.carrega_dados()
    },
    async altera_telabaixo() {
      var results = await api.post(`altera_campobaixo/${this.cd_options_campo}`, this.gera_json())
      console.log(results)
      this.limparbaixo()
      this.onChangeCampo()
      this.carrega_dados()
    },
    async limparcima() {
      this.nm_campo = ''
      this.show_grid = ''
      this.show_tela = ''
      this.cd_tipo_campo = ''
      this.status_cadastrocima = true
      this.cd_template = null
      this.cd_ordem = null
      //this.show = false
    },
    async limparbaixo() {
      this.cd_campo_tela = 0
      this.nm_placeholder_campo_tela = ''
      this.nm_api = ''
      this.nm_parametro_api = ''
      this.displayExpr = ''
      this.valueExpr = ''
      this.searchExpr = ''
      this.searchEnabled = ''
      this.status_cadastrobaixo = true
    },
    onChangeCampo2({ selectedRowsData }) {
      console.log(selectedRowsData[0])
      var data = selectedRowsData[0]
      this.cd_campo_tela = data.cd_campo_tela
      this.cd_tela_empresa = data.cd_tela_empresa
      this.nm_campo = data.nm_campo
      this.cd_tipo_campo = data.cd_tipo_campo
      this.cd_template = data.template
      this.nm_placeholder_campo_tela = data.nm_placeholder
      this.nm_api = data.nm_api == null ? '': data.nm_api
      this.nm_parametro_api = data.nm_parametro_api == null ? '' : data.nm_parametro_api
      this.displayExpr = data.displayExpr == null ? '' : data.displayExpr
      this.valueExpr = data.valueExpr == null ? '' : data.valueExpr
      this.searchExpr = data.searchExpr == null ? '' : data.searchExpr
      this.searchEnabled = data.searchEnabled == null ? '' : data.searchEnabled
      this.show_grid = data.show_grid
      this.show_tela = data.show_tela
      this.cd_ordem = data.ordem
      this.status_cadastrocima = false
      this.status_cadastrobaixo = false
    },
    async onChangeCampo() {
      this.telas = await api.get('consulta_campos', [this.cd_tela_empresa])
      console.log(this.telas)
      this.show = true
    },
    gera_json() {
      var json = {
        'cd_campo_tela': this.cd_campo_tela,
        'cd_tela_empresa': this.cd_tela_empresa,
        'nm_campo': this.nm_campo,
        'cd_tipo_campo': this.cd_tipo_campo,
        'nm_placeholder_campo_tela': this.nm_placeholder_campo_tela,
        'nm_api': this.nm_api,
        'nm_parametro_api': this.nm_parametro_api,
        'displayExpr': this.displayExpr,
        'valueExpr': this.valueExpr,
        'searchExpr': this.searchExpr,
        'searchEnabled': this.searchEnabled,
        'show_grid': this.show_grid,
        'show_tela': this.show_tela,
        'cd_template': this.cd_template,
        'cd_ordem': this.cd_ordem
      }
      return json
    }
  },
  components: {
    DxDataGrid,
    DxRemoteOperations,
    DxGroupPanel,
    DxGrouping,
    DxSelectBox,
    DxSearchPanel,
    DxButton,
    DxPaging,
    DxScrolling,
    DxTextBox,
    DxPager,
    DxFilterRow,
    DxHeaderFilter,
    DxExport,
    DxNumberBox
  }
}
</script>

<style lang="scss">
.DxTextBox {
  margin: 1vh 1vh;
}
</style>